<template>
  <div id="issue" class="issue fill-height">
    <div v-if="imagePhoto || issue">
      <issue-editor :imagePhotoProp="imagePhoto" :issueProp="issue" :prevRoute="prevRoute"></issue-editor>
    </div>
  </div>
</template>

<script>
import IssueEditor from "@/components/issues/IssueEditor.vue";
import { IssueService } from "@/services";
import Helpers from "@/mixins/helpers";
import { mapGetters } from "vuex";

export default {
  name: "IssueEdit",
  metaInfo: {
    title: "Log-it Editor",
  },
  mixins: [Helpers],
  components: {
    IssueEditor,
  },
  computed: {
    ...mapGetters("issue", ["image"]),
  },
  data: () => ({
    issue: null,
    imagePhoto: null,
    prevRoute: null,
  }),
  methods: {
    async loadIssue(issueId) {
      const r = await IssueService.getIssue(issueId);
      this.issue = r.data;
    },
  },
  mounted() {
    let params = this.$route.params;
    if (params.id) {
      this.loadIssue(params.id);
    } else if (this.image) {
      this.imagePhoto = this.image;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
