<template>
  <div class="image-editor">
    <div class="description-wrap px-4 pt-3 pb-3 d-flex" style="border-bottom: 1px solid #444">
      <v-btn class="mr-2 mt-2" small icon v-if="prevRoute && prevRoute.name" :to="{ name: prevRoute.name }"
        ><v-icon class="mr-1">mdi-chevron-left</v-icon>
      </v-btn>
      <v-textarea
        style="margin-left: -6px"
        hide-details
        dense
        filled
        outlined
        v-model="issue.description"
        auto-grow
        rows="1"
        row-height="15"
        class="textarea"
        label="Log-it description"
        @blur="updateIssue"
      >
        <template v-slot:prepend>
          <v-avatar width="50" height="40" tile style="position: relative; top: -8px">
            <img :src="require('@/assets/log-it-logo-opacity.png')" />
          </v-avatar>
        </template>
      </v-textarea>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="margin: 6px 0 0 0px; position: relative; right: -8px"
            icon
            small
            @click="asset = null"
            color="secondary"
            v-bind="attrs"
            v-on="on"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-btn text small @click="deleteIssueConfirm">
                <v-icon small class="mr-2">mdi-delete</v-icon> Delete</v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-toolbar flat color="transparent">
      <v-tooltip bottom z-index="100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            v-bind="attrs"
            v-on="on"
            @click="mode = 'comment'"
            tile
            class="btn-editor mr-2"
            :class="{ active: mode == 'comment' }"
          >
            <v-icon class="mr-2">mdi-comment-text</v-icon>
            Comment
          </v-btn>
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            @click="mode = 'comment'"
            tile
            class="btn-editor mr-2"
            :class="{ active: mode == 'comment' }"
          >
            <v-icon>mdi-comment-text</v-icon>
          </v-btn>
        </template>
        <span>Comment</span>
      </v-tooltip>

      <v-tooltip bottom z-index="100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            v-bind="attrs"
            v-on="on"
            @click="mode = 'draw'"
            tile
            class="btn-editor"
            :class="{ active: mode == 'draw' }"
          >
            <v-icon class="mr-2">mdi-draw</v-icon>
            Draw
          </v-btn>
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            @click="mode = 'draw'"
            tile
            class="btn-editor"
            :class="{ active: mode == 'draw' }"
          >
            <v-icon>mdi-draw</v-icon>
          </v-btn>
        </template>
        <span>Draw</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <div v-if="mode == 'draw'" class="tools d-flex mt-0">
        <v-tooltip bottom z-index="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-2 btn-tool"
              @click="(tool = 'pen'), (isErasing = false)"
              tile
              icon
              :class="{ active: tool == 'pen' }"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
          <span>Pen</span>
        </v-tooltip>
        <!-- <v-btn
          class="mr-2 btn-tool"
          @click="(tool = 'marker'), (isErasing = false)"
          tile
          icon
          :class="{ active: tool == 'marker' }"
        >
          <v-icon>mdi-marker</v-icon>
        </v-btn> -->
        <v-tooltip bottom z-index="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btn-tool mr-2"
              @click="(tool = 'eraser'), (isErasing = true)"
              tile
              icon
              :class="{ active: tool == 'eraser' }"
            >
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
          </template>
          <span>Eraser</span>
        </v-tooltip>
        <v-menu
          style="z-index: 101"
          ref="menu-color-picker"
          v-model="showColorPicker"
          :close-on-content-click="false"
          transition="slide-x-reverse-transition"
          min-width="auto"
          :nudge-left="256"
          :nudge-bottom="44"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btn-tool" icon :class="{ active: tool == 'color-select' }">
              <v-icon :color="pickerColor">mdi-circle-slice-8</v-icon>
            </v-btn>
          </template>
          <v-color-picker
            v-if="showColorPicker"
            class="color-picker"
            dot-size="25"
            swatches-max-height="200"
            mode="hexa"
            v-model="pickerColor"
          ></v-color-picker>
        </v-menu>
      </div>
    </v-toolbar>
    <div
      class="canvas-wrap"
      id="canvasWrap"
      ref="canvasWrap"
      :class="mode + ' ' + tool"
      :width="canvasWidth"
      :height="canvasHeight"
    >
      <canvas
        class="canvas draw"
        ref="canvasDraw"
        :width="canvasWidth"
        :height="canvasHeight"
        @touchstart="handleEvent"
        @touchmove="handleEvent"
        @touchend="handleEvent"
        @mousedown="handleEvent"
        @mousemove="handleEvent"
        @mouseup="handleEvent"
        @mouseout="handleEvent"
        @dragover.prevent="onDragOver"
        @click="handleEvent"
      ></canvas>
      <img id="photo" class="photo" :src="imageSrc" :width="canvasWidth" />
      <div
        class="comments-wrap"
        :style="{
          width: canvasWidth + 'px',
          height: canvasHeight + 'px',
        }"
      >
        <div v-for="(comment, index) in comments" :key="index">
          <div
            class="comment-wrap"
            :class="currentComment?.index == index ? 'active' : ''"
            :style="{
              top: comment.yCoord + '%',
              left: comment.xCoord + '%',
              zIndex: comment.showDetails ? 50 : 40,
            }"
          >
            <!-- :style="dragging[index] ? dragCommentStyle : ''" -->
            <div class="comment" :id="'comment-' + index">
              <div
                class="trigger-show-comment"
                :id="'triggerShowComment_' + index"
                :class="{ 'show-move': comment.showDetails }"
                @mousedown="triggerComment($event, comment)"
                @touchstart="triggerComment($event, comment)"
                @mouseup="showComment(index)"
              >
                <app-user-avatar
                  class="comment-avatar"
                  v-if="comment.ownerId && comment.ownerId != issue.ownerId"
                  :user="{
                    profileImageAssetId: comment.owner?.profileImageAssetId,
                    profileImageAssetKey: comment.owner?.profileImageAssetKey,
                    initials: comment.owner?.initials,
                    userId: comment.owner?.id,
                  }"
                  :size="36"
                />

                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                  <circle class="large-circle" cx="25" cy="25" r="18" fill="#fbecec" />
                  <circle cx="25" cy="25" r="12" :fill="commentColor(comment.severity)" />
                  <text x="25" y="30" font-family="Arial" font-size="16" text-anchor="middle" fill="white">
                    {{ index + 1 }}
                  </text>
                </svg>

                <div
                  class="edit-connect"
                  :class="{ avatar: comment.ownerId && comment.ownerId != issue.ownerId }"
                  v-if="comment.showDetails"
                ></div>
              </div>

              <div
                :style="{
                  left: `calc(${-comment.xCoord}% + 25px)`,
                  top: '15px',
                  width: canvasWidth - 30 + 'px',
                }"
                :id="'editComment_' + index"
                class="edit-comment"
                v-if="comment.showDetails"
              >
                <v-btn class="btn-close-comment" @click="showComment(index)" icon x-small
                  ><v-icon color="grey darken-2" small>mdi-close-thick</v-icon></v-btn
                >
                <div class="d-flex align-center">
                  <span class="mr-2 mt-0 pt-0 pl-2 overline">Log-It</span>
                  <v-spacer></v-spacer>
                  <v-icon style="position: relative; right: 0; top: -4px" large :color="commentColor(comment.severity)">
                    mdi-fire
                  </v-icon>
                </div>

                <v-textarea
                  dense
                  filled
                  auto-grow
                  rows="1"
                  row-height="15"
                  hide-details="true"
                  class="textarea"
                  v-model="comment.comment"
                ></v-textarea>
                <div class="text-right mt-1" v-if="comment.ownerId && comment.ownerId != issue.ownerId">
                  by {{ comment.ownerFriendlyName }}
                </div>
                <v-slider
                  class="mt-2"
                  v-model="comment.severity"
                  :tick-labels="ticksLabels"
                  :color="commentColor(comment.severity)"
                  min="1"
                  max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                >
                </v-slider>
                <span class="d-flex" v-if="!(comment.ownerId && comment.ownerId != issue.ownerId)">
                  <v-btn color="primary" small class="mt-2" @click="submitComment(comment, true)">Submit</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="comment.id ? removeCommentConfirm(index, $event) : removeComment(index, $event)"
                    x-small
                    class="mt-2"
                    >Delete</v-btn
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { AssetService, IssueService } from "@/services";
import { mapActions } from "vuex";
import AppConfirmDialog from "@/components/AppConfirmDialog.vue";
import AppUserAvatar from "@/components/AppUserAvatar.vue";

export default {
  name: "IssueEditor",
  data() {
    return {
      isDrawing: false,
      isErasing: false,
      isDragging: false,
      lastX: 0,
      lastY: 0,
      ctxMarkup: null,
      canvasWidth: 800,
      canvasHeight: 600,
      mode: "comment",
      tool: "pen",
      comments: [],
      offset: { xCoord: 0, yCoord: 0 },
      currentComment: null,
      ticksLabels: ["Store-It", "Review-It", "Escalate-It"],
      pickerColor: "#FF0000",
      showColorPicker: false,
      issue: { description: "" },
      issueId: null,
      imageSrc: null,
    };
  },
  components: {
    AppConfirmDialog,
    AppUserAvatar,
  },
  props: {
    imagePhotoProp: {
      type: Object,
      default: null,
    },
    issueProp: {
      type: Object,
      default: null,
    },
    prevRoute: {
      type: Object,
      default: null,
    },
  },
  watch: {
    imagePhotoProp(n, o) {
      console.log("Prop changed from", o, "to", n);
    },
  },
  computed: {
    dragCommentStyle() {
      return {
        transform: `translate(${this.position.xCoord}px, ${this.position.yCoord}px)`,
      };
    },
  },
  methods: {
    ...mapActions("loader", ["pending", "done"]),
    ...mapActions("issue", ["setImage"]),
    reset() {
      this.$emit("reset");
    },

    async submitComment(comment, snack = false) {
      // eslint-disable-next-line no-unused-vars
      const { index, showDetails, ...params } = comment;

      let r;
      if (comment.id) {
        r = await IssueService.patchIssueComment(params);
      } else {
        r = await IssueService.addIssueComment(params);
      }

      this.comments[index].id = r.data?.issueCommentId;
      this.hideComment(index);
      if (snack) {
        this.$snackbar.showMessage({
          content: this.$t("Comment saved"),
          color: "success",
          timeout: "",
        });
      }
    },

    commentColor(severity) {
      if (severity < 2) return "#FFAB00";
      if (severity < 3) return "#E65100";
      if (severity < 4) return "#B71C1C";
      return "red";
    },

    showComment(index) {
      if (this.comments[index].showDetails && !this.isDragging) {
        if (!this.comments[index].id) {
          this.submitComment(this.comments[index], true);
        }

        this.$set(this.comments[index], "showDetails", false);
      } else {
        this.$set(this.comments[index], "showDetails", true);
      }
    },

    hideComment(index) {
      this.$set(this.comments[index], "showDetails", false);
    },

    handleEvent(event) {
      if (this.mode == "draw") {
        switch (event.type) {
          case "touchstart":
            this.startDrawing(event);
            break;
          case "touchmove":
            this.handleTouchMove(event);
            break;
          case "touchend":
            this.stopDrawing(event);
            break;
          case "mousedown":
            this.startDrawing(event);
            break;
          case "mousemove":
            this.drawMouse(event);
            break;
          case "mouseup":
          case "mouseout":
            this.stopDrawing(event);
            break;
        }
      } else if (this.mode == "comment") {
        switch (event.type) {
          case "click":
            if (this.mode === "comment") {
              this.addComment(event);
            }
            break;
        }
      }
    },

    triggerComment(event, comment) {
      if (comment.ownerId && comment.ownerId != this.issue.ownerId) return;
      event.stopPropagation();
      this.currentComment = comment;
      this.offset.xCoord = this.currentComment.xCoord;
      this.offset.yCoord = this.currentComment.yCoord;
      document.addEventListener("mousemove", this.onCommentDrag);
      document.addEventListener("mouseup", this.endCommentDrag);
      document.addEventListener("touchmove", this.onCommentDrag);
      document.addEventListener("touchend", this.endCommentDrag);
    },

    onCommentDrag(event) {
      if (!this.currentComment) return;
      //this.isDragging = true;
      const { xPercent, yPercent } = this.getCoords(event);
      if (xPercent < 1 || xPercent > 99 || yPercent < 1 || yPercent > 99) return;

      const comment = document.getElementById("editComment_" + this.currentComment.index);

      if (xPercent > 70) {
        comment?.classList.toggle("comment-left", true);
      } else {
        comment?.classList.toggle("comment-left", false);
      }

      this.currentComment.xCoord = xPercent;
      this.currentComment.yCoord = yPercent;
    },

    endCommentDrag() {
      document.removeEventListener("mousemove", this.onCommentDrag);
      document.removeEventListener("mouseup", this.endCommentDrag);
      document.removeEventListener("touchmove", this.onCommentDrag);
      document.removeEventListener("touchend", this.endCommentDrag);
      const idx = this.currentComment.index;
      const comment = this.comments[idx];
      this.$set(this.comments[idx], "xCoord", this.currentComment.xCoord);
      this.$set(this.comments[idx], "yCoord", this.currentComment.yCoord);

      this.currentComment = null;
      this.offset = { x: 0, y: 0 };

      setTimeout(() => {
        this.isDragging = false;
        if (comment.id) {
          IssueService.patchIssueComment(this.comments[idx]);
        }
      }, 100);
    },

    addComment(event) {
      const { xPercent, yPercent } = this.getCoords(event);
      this.comments.push({
        issueId: this.issueId,
        index: this.comments.length,
        xCoord: xPercent,
        yCoord: yPercent,
        comment: "",
        severity: 2,
        showDetails: true,
      });
    },

    getCoords(event) {
      const canvas = this.$refs.canvasWrap;
      const rect = canvas.getBoundingClientRect();
      let x, y;

      if (event.touches && event.touches.length > 0) {
        x = event.touches[0].clientX - rect.left;
        y = event.touches[0].clientY - rect.top;
      } else {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      }
      return {
        xPercent: (x / rect.width) * 100,
        yPercent: (y / rect.height) * 100,
      };
    },

    async removeCommentConfirm(index, event) {
      if (await this.$refs.confirm.open("Confirm", this.$t("c_confirm.t_confirm_delete"))) {
        this.removeComment(index, event);
      }
    },

    async removeComment(index, event) {
      if (event) event.stopPropagation();
      const comment = this.comments[index];
      if (comment?.id) {
        await IssueService.deleteIssueComment(this.comments[index]?.id);
      }
      this.comments.splice(index, 1);
    },

    async loadImage() {
      const canvasWrap = this.$refs.canvasWrap;
      this.canvasWidth = canvasWrap.offsetWidth;
      this.imageSrc = `data:image/jpeg;base64,${this.imagePhotoProp?.base64String}`;
      const img = new Image();
      img.src = this.imageSrc;

      img.onload = () => {
        let aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / aspectRatio;
      };

      const resizeListener = () => {
        const canvasWrap = this.$refs.canvasWrap;
        this.canvasWidth = canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;
      };

      window.addEventListener("resize", resizeListener);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });

      this.imageUpload();
    },

    async imageUpload() {
      if (!this.imagePhotoProp) return;
      this.pending();
      const base64String = this.imagePhotoProp.base64String;
      const base64Data = new Buffer.from(base64String, "base64");
      const photoExt = "jpg";
      const photoName = `log-it-photo_${new Date().getTime()}.${photoExt}`;

      const payload = {
        name: photoName,
        description: "",
        originalFileName: photoName,
        mimeType: "image/jpeg",
        cacheable: true,
        permitCompany: true,
      };

      AssetService.uploadAssetBegin(payload)
        .then((a) => {
          let params = {
            method: "PUT",
            headers: {
              "Content-Type": this.imagePhotoProp.format,
              "Content-Encoding": "base64",
            },
            body: base64Data,
          };
          fetch(a.data.uploadUrl, params).then((r) => {
            if (r.ok) {
              const publicId = a.data.assetGuid;
              AssetService.uploadAssetComplete({ assetId: a.data.assetId }).then(async () => {
                const params = {
                  description: "",
                  status: "n",
                  imageAssetId: a.data.assetId,
                  imageAssetPublicId: publicId,
                };
                const r = await IssueService.addIssue(params);
                this.issueId = r.data?.issueId;
              });
            } else {
              AssetService.deleteAsset({ assetId: a.data.assetId });
            }
          });
        })
        .finally(() => {
          this.done();
        });

      setTimeout(() => {
        this.done();
      }, 8000);
    },

    async markupUpload() {
      this.pending();
      const existingMarkupAssetId = this.issue?.markupAssetId;
      const base64String = this.$refs.canvasDraw.toDataURL();
      const base64Data = new Buffer.from(base64String.replace(/^data:image\/\w+;base64,/, ""), "base64");
      const photoExt = "png";
      const photoName = `log-it-markup_${this.issueId}.${photoExt}`;
      const payload = {
        name: photoName,
        description: "",
        originalFileName: photoName,
        mimeType: "image/png",
        cacheable: true,
        permitCompany: true,
      };

      try {
        const a = await AssetService.uploadAssetBegin(payload);
        let params = {
          method: "PUT",
          headers: {
            "Content-Type": photoExt,
            "Content-Encoding": "base64",
          },
          body: base64Data,
        };

        const r = await fetch(a.data.uploadUrl, params);

        if (r.ok) {
          const publicId = a.data.assetGuid;
          const markupAssetId = a.data.assetId;
          this.issue.markupAssetId = markupAssetId;

          await AssetService.uploadAssetComplete({ assetId: markupAssetId });

          const params = {
            id: this.issueId,
            markupAssetId: a.data.assetId,
            markupAssetPublicId: publicId,
          };

          const r = await IssueService.patchIssue(params);

          if (existingMarkupAssetId && !r.hasErrors) {
            await AssetService.deleteAsset(existingMarkupAssetId);
          }
        } else {
          await AssetService.deleteAsset(a.data.assetId);
        }
      } catch (error) {
        console.log("An error occurred during the asset upload process:", error);
      } finally {
        this.done();
      }
      setTimeout(() => {
        this.done();
      }, 8000);
    },

    async updateIssue() {
      if (this.issueId && this.issue.description.length > 0) {
        const params = { id: this.issueId, description: this.issue.description };
        await IssueService.patchIssue(params);
        this.$snackbar.showMessage({
          content: this.$t("Description saved"),
          color: "success",
          timeout: "",
        });
      }
    },

    async deleteIssueConfirm() {
      if (await this.$refs.confirm.open("Confirm", this.$t("c_confirm.t_confirm_delete"))) {
        this.deleteIssue();
      }
    },

    async deleteIssue() {
      const params = { id: this.issueId, status: "d" };
      await IssueService.patchIssue(params);
      this.$snackbar.showMessage({
        content: this.$t("Issue deleted"),
        color: "success",
        timeout: "",
      });
      this.$router.push({ name: "issue" });
    },

    startDrawing(e) {
      this.isDrawing = true;
      this.showColorPicker = false;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },

    handleTouchMove(e) {
      e.preventDefault();
      this.drawTouch(e);
    },

    toggleEraser() {
      this.isErasing = !this.isErasing;
    },

    drawMouse(e) {
      if (!this.isDrawing) return;

      if (this.isErasing) {
        this.ctxMarkup.globalCompositeOperation = "destination-out"; // Set the eraser mode
        this.ctxMarkup.lineWidth = 10; // You can set eraser size
        this.ctxMarkup.strokeStyle = "rgba(0,0,0,1)";
      } else {
        this.ctxMarkup.globalCompositeOperation = "source-over"; // Set the drawing mode
        this.ctxMarkup.lineWidth = 5; // You can set pen size
        this.ctxMarkup.strokeStyle = this.pickerColor;
        this.ctxMarkup.lineWidth = 5;
      }

      this.ctxMarkup.beginPath();
      //this.ctxMarkup.moveTo(this.lastX, this.lastY);
      this.ctxMarkup.moveTo(...this.getPosition(e));
      this.ctxMarkup.lineTo(e.offsetX, e.offsetY);
      this.ctxMarkup.stroke();
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },

    drawTouch(e) {
      if (!this.isDrawing) return;
      const rect = this.$refs.canvasDraw.getBoundingClientRect();
      const x = e.touches[0].clientX - rect.left;
      const y = e.touches[0].clientY - rect.top;
      this.ctxMarkup.strokeStyle = this.pickerColor;
      this.ctxMarkup.lineWidth = 5;

      if (this.isErasing) {
        this.ctxMarkup.globalCompositeOperation = "destination-out";
        this.ctxMarkup.lineWidth = 10;
        this.ctxMarkup.strokeStyle = "rgba(0,0,0,1)";
      } else {
        this.ctxMarkup.globalCompositeOperation = "source-over";
        this.ctxMarkup.lineWidth = 5;
        this.ctxMarkup.strokeStyle = this.pickerColor;
        this.ctxMarkup.lineWidth = 5;
      }

      this.ctxMarkup.beginPath();
      this.ctxMarkup.moveTo(this.lastX, this.lastY);
      this.ctxMarkup.lineTo(x, y);
      this.ctxMarkup.stroke();
      this.lastX = x;
      this.lastY = y;
    },

    async stopDrawing() {
      if (!this.isDrawing) {
        return;
      }
      this.isDrawing = false;
      this.markupUpload();
    },

    getPosition(e) {
      const canvas = this.$refs.canvasDraw;
      const rect = canvas.getBoundingClientRect();

      if (e.touches) {
        const touch = e.touches[0];
        return [touch.clientX - rect.left, touch.clientY - rect.top];
      } else {
        return [this.lastX, this.lastY];
      }
    },

    onDragStart(event) {
      event.dataTransfer.setData("text/plain", event.target.id);
    },

    onDragOver(event) {
      event.preventDefault();
    },

    ///////// Load Issue ///////////////

    async loadIssue() {
      this.issue = this.issueProp;
      this.issueId = this.issue.id;
      await this.getPhotoBase64();
      if (this.issue.markupAssetId) {
        await this.getMarkupBase64();
      }
      this.getIssueComments();
    },

    async getIssueComments() {
      let params = {
        issueId: this.issueId,
        "paging.orderbyfield": "id",
        "paging.ascending": true,
        "paging.skipnum": 0,
        "paging.takenum": 999999,
      };

      const r = await IssueService.getIssueComments(params);
      const comments = r.data;
      comments.forEach((c, idx) => {
        c.index = idx;
        this.comments.push(c);
      });
    },

    async getMarkupBase64() {
      let params = { assetId: this.issue.markupAssetId, token: this.issue.markupAssetPublicId };
      const r = await AssetService.downloadAsset(params);
      if (r?.data?.downloadUrl == null) return;
      const d = await fetch(r.data.downloadUrl);
      const imageBlob = await d.blob();
      const base64 = await this.blobToBase64(imageBlob);

      const img = new Image();
      let aspectRatio = img.naturalWidth / img.naturalHeight;
      const canvasDraw = this.$refs.canvasDraw;
      this.ctxMarkup = canvasDraw.getContext("2d");

      img.onload = () => {
        aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasWidth = canvasDraw.offsetWidth;
        this.canvasHeight = this.canvasWidth / aspectRatio;
        this.$nextTick(() => {
          this.ctxMarkup.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
        });
      };

      const base64String = base64.split(",")[1];
      // // //reconstruct as png:
      const imgSrc = `data:image/png;base64,${base64String}`;
      img.src = imgSrc;
      const resizeListener = () => {
        this.canvasWidth = canvasDraw.offsetWidth;
        this.canvasHeight = this.canvasWidth / aspectRatio;
        this.$nextTick(() => {
          this.ctxMarkup.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
        });
      };

      window.addEventListener("resize", resizeListener);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });
    },

    async getPhotoBase64() {
      let params = { assetId: this.issue.imageAssetId, token: this.issue.imageAssetPublicId };
      const r = await AssetService.downloadAsset(params);
      if (r?.data?.downloadUrl == null) return;
      const d = await fetch(r.data.downloadUrl);
      const imageBlob = await d.blob();
      const base64 = await this.blobToBase64(imageBlob);
      const canvasWrap = this.$refs.canvasWrap;
      this.canvasWidth = canvasWrap.offsetWidth;
      const base64String = base64.split(",")[1];
      // // //reconstruct as jpeg:
      this.imageSrc = `data:image/jpeg;base64,${base64String}`;
      const img = new Image();

      img.src = this.imageSrc;

      img.onload = () => {
        this.canvasWidth = this.$refs.canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;
      };

      const resizeListener = () => {
        const canvasWrap = this.$refs.canvasWrap;
        this.canvasWidth = canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;
      };
      window.addEventListener("resize", resizeListener);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });
    },

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },
  },
  mounted() {
    if (this.issueProp) {
      this.loadIssue();
    } else if (this.imagePhotoProp) {
      console.log("image photo prop");
      this.loadImage();
      this.ctxMarkup = this.$refs.canvasDraw.getContext("2d");
    }
  },
  beforeDestroy() {
    this.setImage(null);
    document.removeEventListener("mousemove", this.onCommentDrag);
    document.removeEventListener("mouseup", this.endCommentDrag);
    document.removeEventListener("touchmove", this.onCommentDrag);
    document.removeEventListener("touchend", this.endCommentDrag);
  },
};
</script>

<style lang="scss">
.theme--dark .image-editor {
  background: #272727;
}
.image-editor {
  background: #eee;
  padding-bottom: 15px;
  // .toolbar {
  //   position: fixed;
  //   top: 66px;
  //   right: 15px;
  //   width: 360px;
  //   border: 1px solid #eee;
  //   border-radius: 5px;
  // }
  img.photo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .canvas {
    width: 100%;
    height: auto;
  }
  .comment-nav,
  .tools {
    margin-top: -10px;
  }
  .btn-editor {
    //border: 2px solid #4f4f4f;
    border-radius: 3px !important;
    padding: 0 12px !important;
    &.active {
      background: rgba(255, 2555, 255, 0.25);
    }
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .btn-tool {
    border-radius: 3px !important;
    //border: 2px solid #4f4f4f;
    padding: 4px 12px !important;
    height: unset !important;
    min-width: unset !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
    &.active {
      background: rgba(255, 2555, 255, 0.25);
      //border: 2px solid #ccc;
    }
  }
  .canvas-wrap {
    position: relative;
    border-top: 2px solid #aaa;
    z-index: 4;
    margin: 0 15px;
    .canvas {
      position: absolute;
      top: 0;
      left: 0;
      &.photo {
        z-index: 10;
      }
      &.draw {
        z-index: 20;
      }
    }
    &.draw {
      cursor: url("~@/assets/pencil.svg") 4 28, auto;
    }
    &.eraser {
      cursor: url("~@/assets/eraser.svg") 4 28, auto;
    }
    &.comment {
      cursor: url("~@/assets/comment-text.svg") 14 28, auto;
    }
    .comments-wrap {
      position: relative;
      .comment-wrap {
        margin: -25px 0px 0px -25px;
        position: absolute;
        z-index: 40;
        &.active {
          .trigger-show-comment .large-circle {
            stroke: rgba(250, 246, 177, 0.4);
            stroke-width: 12;
          }
        }
      }
      .trigger-show-comment {
        cursor: pointer;
        touch-action: none;
        width: 50px;
        height: 50px;
        &.show-move {
          cursor: move;
        }
        .comment-avatar {
          z-index: 20;
          position: relative;
          border: 2px solid #fff !important;
          left: 7px;
          top: 7px;
        }
        .edit-connect {
          position: relative;
          left: 23px;
          top: -15px;
          height: 30px;
          width: 5px;
          background: #fff;
          &.avatar {
            left: 23px;
            top: 0;
          }
        }
      }
      .edit-comment {
        cursor: default;
        position: relative;
        top: 15px;
        left: 0;
        background: #333;
        padding: 8px 15px 15px;
        border: 5px solid #fbecec;
        border-radius: 8px;
        width: 360px;
        &.comment-left {
          left: -280px;
          .edit-connect {
            left: 236px;
          }
        }
        .textarea textarea {
          line-height: 1.3;
          padding-bottom: 10px;
        }
        // .v-slider__track-background {
        //   background-color: transparent !important;
        // }
        .v-slider__track-fill {
          background-color: #5c5c5c !important;
        }
        .btn-close-comment {
          position: absolute;
          right: -15px;
          top: -15px;
          width: 25px;
          height: 25px;
          background: #fbecec;
        }
      }
    }
  }
  .image-editor canvas {
    border: 1px solid #ccc;
  }
  @keyframes severity-level {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }
}

@media (min-width: 768px) {
  .image-editor {
    .canvas-wrap {
      .comments-wrap {
        .edit-comment {
          position: relative;
          // top: -95px !important;
          // left: 60px !important;
          max-width: 420px !important;

          .edit-connect {
            position: relative;
            border-bottom: 5px solid #fbecec;
            left: -45px;
            top: 55px;
            width: 30px;
          }
          &.comment-left {
            left: -280px;
            .edit-connect {
              left: 236px;
            }
          }
        }
      }
    }
  }
}
</style>
