<template>
  <v-avatar color="grey darken-1" :size="size">
    <img v-if="imageUrl" :src="imageUrl" @error="imageUrlError" />
    <span v-else class="white--text" :style="'font-size:' + fontSize + 'px;'">
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script>
import { AssetService } from "@services";
import idb from "@/api/idb";

export default {
  name: "AppUserAvatar",

  props: {
    user: {
      type: Object,
    },
    size: {
      type: Number,
      default: 32,
    },
  },

  watch: {
    user(n, o) {
      if (n.profileImageAssetId !== o.profileImageAssetId) {
        console.log(n.profileImageAssetId);
        this.getProfileImageFromS3();
      }
    },
  },

  data() {
    return {
      imageUrl: null,
    };
  },

  computed: {
    fontSize() {
      return Math.round(this.size / 2);
    },
    initials() {
      if (this.user?.initials == null) {
        return "?";
      }

      return this.user.initials;
    },
  },

  methods: {
    async getProfileImageFromS3() {
      try {
        let params = { assetId: this.user.profileImageAssetId, token: this.user.profileImageAssetKey };

        if (this.user.userId == this.$store.state.user?.user?.userId) {
          params = { assetId: this.user.profileImageAssetId };
        }

        const r = await AssetService.downloadAsset(params);
        if (r?.data?.downloadUrl == null) return;
        const d = await fetch(r.data.downloadUrl);
        const imageBlob = await d.blob();
        this.imageUrl = URL.createObjectURL(imageBlob);
        idb.putS3Item(this.user.profileImageAssetKey, imageBlob);
      } catch (err) {
        //silent error
      }
    },
    imageUrlError() {
      this.imageUrl = null;
    },
  },

  mounted() {
    if (!this.user?.profileImageAssetId) {
      return; // No profile image just use initials
    }

    idb.getS3Item(this.user.profileImageAssetKey).then((s3Item) => {
      if (s3Item?.value == null) {
        this.getProfileImageFromS3();
        return;
      }
      this.imageUrl = URL.createObjectURL(s3Item.value);
    });
  },
};
</script>
